<template>
  <v-form ref="form" v-model="valid" lazy-validation>
    <v-layout column wrap>
      <v-flex>
        <v-autocomplete
          ref="personAutoComplete"
          :value="person"
          :items="people"
          :loading="isFetching"
          :search-input.sync="search"
          :hint="searchFeedback"
          persistent-hint
          clearable
          hide-no-data
          no-filter
          item-text="name"
          solo
          placeholder="Buscar por nome, razão social, CPF ou CNPJ"
          prepend-inner-icon="mdi-account-search"
          @input="selectPerson"
          return-object
        />
      </v-flex>

      <v-flex class="mb-3" v-if="!_.isEmpty(person)">
        <v-list class="grey lighten-4">
          <v-list-tile>
            <v-list-tile-content>
              <v-list-tile-sub-title>
                {{ person.cpf_cnpj.length > 11 ? 'CNPJ' : 'CPF' }}
              </v-list-tile-sub-title>
              <v-list-tile-title v-if="person.cpf_cnpj.length > 11">
                {{ person.cpf_cnpj | cnpj }}
              </v-list-tile-title>
              <v-list-tile-title v-else>
                {{ person.cpf_cnpj | cpf }}
              </v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>

          <v-list-tile>
            <v-list-tile-content>
              <v-list-tile-sub-title>Endereço</v-list-tile-sub-title>
              <v-list-tile-title>{{ address }}</v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>

          <v-list-tile>
            <v-list-tile-content>
              <v-list-tile-sub-title>Fone</v-list-tile-sub-title>
              <v-list-tile-title>{{ person.cellphone | phone }}</v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
        </v-list>
      </v-flex>
    </v-layout>
  </v-form>
</template>

<script>
import dateFns from 'date-fns';
import format from 'date-fns/format';
import validationRules from '@/mixins/validation-rules';
import masks from '@/utils/masks';
import formatters from '@/utils/formatters';
import { formattedDateFilter } from '@/mixins';
import { isEmpty } from 'lodash';
import PersonService from '@/services/person-service';

export default {
  name: 'MtrPersonForm',
  mixins: [formatters, formattedDateFilter],
  props: {
    profile: {
      type: String,
      validator: value => ['generator', 'transporter', 'receiver', 'temporary_storage'].includes(value),
    },

    transportedAt: {
      type: Number,
    },

    title: {
      type: String,
      required: true,
    },

    description: {
      type: String,
    },

    tempStorager: {
      type: Boolean,
    },
    person: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      masks,
      validationRules,
      formData: {},
      cpfCnpj: 'CNPJ',
      valid: true,
      showDatepicker: false,
      date: null,
      fetchedCnpj: '',
      isFetching: false,
      searchFeedback: '',
      people: [],
      isLoading: false,
      search: null,
      format,
    };
  },
  computed: {
    address() {
      const a = this.person.address;
      let address = '';
      address += `${a.street}, ${a.number}, `;
      address += a.complement ? `${a.complement}, ` : '';
      address += `${a.neighborhood}, ${a.city.name}, ${a.federal_unit.name}`;
      return address;
    },
  },
  watch: {
    search(val) {
      if (this.person && val === this.person.name) return;
      if (val === undefined || val === null || val.length < 3) return;

      this.isFetching = true;
      this.searchFeedback = '';
      const searchQuery = {
        profile: this.profile,
        q: this.search,
      };

      PersonService.search(searchQuery)
        .then(({ data }) => {
          if (data.length === 0) {
            this.searchFeedback = `${this.translateProfile(this.profile)} não encontrado.`;
          }
          this.people = data;
        })
        .catch((err) => {
          this.$store.dispatch('application/toggleSnackbar', {
            show: true,
            timeout: 6000,
            color: 'error',
            message: err.message,
            messageIcon: 'mdi-alert-outline',
          });
        })
        .finally(() => {
          this.isFetching = false;
        });
    },
    person(val) {
      if (!isEmpty(val)) {
        this.search = val.name;
        this.people = [val];
      }
    },
  },
  methods: {
    setDate() {
      const timestamp = dateFns.getTime(this.date);
      this.$emit('update:transportedAt', timestamp);
    },
    selectPerson(person) {
      if (person !== null && person !== undefined) {
        this.$emit('update:person', person);
      } else {
        this.$emit('update:person', {});
      }
      this.$refs.personAutoComplete.blur();
    },
    closeDatepicker() {
      setTimeout(() => {
        this.showDatepicker = false;
      }, 200);
    },
    translateProfile(profile) {
      if (profile === 'generator') {
        return 'Gerador';
      } else if (profile === 'transporter') {
        return 'Transportador';
      } else if (profile === 'temporary_storage') {
        return 'Armazenador Temporário';
      }
      return 'Destinador';
    },
  },
};
</script>

<style lang="css" scoped>
.title {
  position: relative;
  padding-left: 15px;
}

.title span:before {
  content: '';
  position: absolute;
  height: 5px;
  border-top: 8px solid #01c469;
  top: 5px;
  width: 20px;
  left: -24px;
  border-radius: 0 10px 10px 0;
}

.subheader {
  padding-left: 15px;
}
</style>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-form",
    {
      ref: "form",
      attrs: { "lazy-validation": "" },
      model: {
        value: _vm.valid,
        callback: function ($$v) {
          _vm.valid = $$v
        },
        expression: "valid",
      },
    },
    [
      _c(
        "v-layout",
        { attrs: { column: "", wrap: "" } },
        [
          _c(
            "v-flex",
            [
              _c("v-autocomplete", {
                ref: "personAutoComplete",
                attrs: {
                  value: _vm.person,
                  items: _vm.people,
                  loading: _vm.isFetching,
                  "search-input": _vm.search,
                  hint: _vm.searchFeedback,
                  "persistent-hint": "",
                  clearable: "",
                  "hide-no-data": "",
                  "no-filter": "",
                  "item-text": "name",
                  solo: "",
                  placeholder: "Buscar por nome, razão social, CPF ou CNPJ",
                  "prepend-inner-icon": "mdi-account-search",
                  "return-object": "",
                },
                on: {
                  "update:searchInput": function ($event) {
                    _vm.search = $event
                  },
                  "update:search-input": function ($event) {
                    _vm.search = $event
                  },
                  input: _vm.selectPerson,
                },
              }),
            ],
            1
          ),
          !_vm._.isEmpty(_vm.person)
            ? _c(
                "v-flex",
                { staticClass: "mb-3" },
                [
                  _c(
                    "v-list",
                    { staticClass: "grey lighten-4" },
                    [
                      _c(
                        "v-list-tile",
                        [
                          _c(
                            "v-list-tile-content",
                            [
                              _c("v-list-tile-sub-title", [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.person.cpf_cnpj.length > 11
                                        ? "CNPJ"
                                        : "CPF"
                                    ) +
                                    "\n            "
                                ),
                              ]),
                              _vm.person.cpf_cnpj.length > 11
                                ? _c("v-list-tile-title", [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm._f("cnpj")(_vm.person.cpf_cnpj)
                                        ) +
                                        "\n            "
                                    ),
                                  ])
                                : _c("v-list-tile-title", [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm._f("cpf")(_vm.person.cpf_cnpj)
                                        ) +
                                        "\n            "
                                    ),
                                  ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-list-tile",
                        [
                          _c(
                            "v-list-tile-content",
                            [
                              _c("v-list-tile-sub-title", [_vm._v("Endereço")]),
                              _c("v-list-tile-title", [
                                _vm._v(_vm._s(_vm.address)),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-list-tile",
                        [
                          _c(
                            "v-list-tile-content",
                            [
                              _c("v-list-tile-sub-title", [_vm._v("Fone")]),
                              _c("v-list-tile-title", [
                                _vm._v(
                                  _vm._s(_vm._f("phone")(_vm.person.cellphone))
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }